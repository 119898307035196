<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 mt-8">
    <div class="col-span-1">
      <template>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 md:mb-10">
          <div class="col-span-1 items-center inline-flex">
            <router-link :to="{ name: 'marketplace' }"
              ><ion-icon
                name="arrow-back-outline"
                class="text-deal text-xl mr-2 mt-1"
              ></ion-icon
            ></router-link>
            <h1 class="text-13 text-gray-700 font-bold">
              All Vendors
            </h1>
          </div>
          <div class="col-span-1">
            <div class="relative">
              <input
                type="search"
                name="search"
                class="border border-solid border-blue-200 rounded placeholder-gray-500 outline-none pl-10 pr-2 py-3 text-sm w-full"
                placeholder="Search for vendors"
                v-model="searchQuery"
                @keyup.prevent="getAllCategoryVendors"
              />
              <ion-icon
                name="search-outline"
                class="text-gray-500 absolute z-10 text-lg ml-3 left-0"
                style="top: 50%; transform: translateY(-50%);"
              ></ion-icon>
            </div>
          </div>
        </div>

        <sm-loader v-if="vendors.loading" class="flex justify-start mt-10" />
        <grid :items="vendors.data" @selected="selectVendor" v-else></grid>

        <no-result v-if="noResult">
          No result found for this category
        </no-result>

        <branch
          v-if="selectedVendor"
          ref="branch"
          :vendor="selectedVendor"
          :key="componentKey"
          @success="success"
        />
      </template>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Grid: () => import("./components/Grid"),
    Branch: () => import("./components/branch/Index"),
    NoResult: () => import("./components/NoResult"),
  },
  data() {
    return {
      vendors: this.$options.resource([]),
      searchQuery: "",
      componentKey: 0,
      selectedVendor: null
    };
  },
  computed: {
    categoryId() {
      return this.$route.params.id;
    },
    noResult() {
      return !this.vendors.loading && this.vendors.data?.length === 0;
    }
  },
  beforeMount() {
    this.getAllCategoryVendors();
  },
  watch: {
    "selectedVendor.business_name"() {
      this.componentKey += 1;
    }
  },
  methods: {
    async getAllCategoryVendors() {
      this.vendors.setLoading();
      await this.sendRequest(
        "personal.marketplace.categoryVendors",
        this.categoryId,
        this.searchQuery,
        {
          success: response =>
            (this.vendors.data = response.data.datatable.data),
          error: error => console.log(error)
        }
      );
      this.vendors.loading = false;
    },
    success() {
      this.componentKey += 1;
      this.$refs.branch.close();
      this.$refs.successModal.openSuccessModal();
    },
    selectVendor(vendor) {
      this.selectedVendor = { ...vendor };
      this.$nextTick(() => {
        this.componentKey += 1;
        setTimeout(() => this.$refs.branch.open(), 300);
      });
    }
  },
  mounted() {
    this.$el.addEventListener("scroll", () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;

      if (scrollTop + window.innerHeight >= document.body.clientHeight) {
        console.log(scrollTop);
      }
    });
  }
};
</script>
